import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "@components/elements/section"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"
import ScrollTrigger from "@components/elements/scrollTrigger"

import styles from "./ctaSection.module.scss"

interface CtaSectionProps {
  title?: string
  text?: string
  cta: CtaProps
}

const CtaSection = ({ title, text, cta }: CtaSectionProps) => (
  <Section className={styles.ctaSection}>
    <ScrollTrigger className={styles.content}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {text && <Wysiwyg className={styles.text} contentHtml={text} />}
      <Cta {...cta} className={styles.buttonCta} />
    </ScrollTrigger>
    <StaticImage
      src="../images/bg-plants.jpg"
      className={styles.background}
      placeholder="blurred"
      objectFit="cover"
      alt=""
    />
  </Section>
)

export default CtaSection
