import * as React from "react"
import { useRef } from "react"
import cx from "classnames"

import useIntersection from "@lib/useIntersection"

import styles from "./scrollTrigger.module.scss"

interface ScrollTriggerProps {
  className?: string
  children?: React.ReactNode
}

const ScrollTrigger = ({ className, children }: ScrollTriggerProps) => {
  const domRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersection(domRef, {
    threshold: 1,
    freezeOnceVisible: true,
  })
  const isVisible = !!entry?.isIntersecting

  return (
    <div
      className={cx(styles.scrollTrigger, className, {
        [styles.isVisible]: isVisible,
      })}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export default ScrollTrigger
