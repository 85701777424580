import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import AboutHero from "@components/aboutHero"
import Persons from "@components/persons"
import CtaSection from "@components/ctaSection"

const About = ({ data }: any) => {
  const { heroData, personsData, ctaSectionData } = data.pageData

  return (
    <Layout title="About" description="">
      <AboutHero {...heroData} {...heroData.left} {...heroData.list} />
      <Persons {...personsData} />
      <CtaSection {...ctaSectionData} />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    pageData: aboutJson {
      heroData: hero {
        intro
        title
        subtitle
        left {
          leftIntro: intro
          leftTitle: title
        }
        list {
          listTitle: title
          listItems: items {
            title
          }
        }
      }
      personsData: persons {
        title
        text
        items {
          name
          position
          text
          image: imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ctaSectionData: ctaSection {
        title
        text
        cta {
          text
          url
        }
      }
    }
  }
`
