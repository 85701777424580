import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"

import Hex from "@images/hex.inline.svg"

import styles from "./aboutHero.module.scss"

interface AboutHeroProps {
  intro?: string
  title: string
  subtitle?: string
  leftIntro?: string
  leftTitle?: string
  listTitle?: string
  listItems: Array<{
    title: string
  }>
}

const AboutHero = ({
  intro,
  title,
  subtitle,
  leftIntro,
  leftTitle,
  listTitle,
  listItems,
}: AboutHeroProps) => (
  <section className={styles.aboutHero}>
    <Container className={styles.container}>
      <div className={styles.header}>
        <Hex className={styles.headerBackground} />
        {intro && <div className={styles.headerIntro}>{intro}</div>}
        <h1 className={styles.headerTitle}>{title}</h1>
        {subtitle && <div className={styles.headerSubtitle}>{subtitle}</div>}
      </div>

      <div className={styles.left}>
        <StaticImage
          src="../images/about-plant-illustration.png"
          className={styles.leftPicture}
          placeholder="blurred"
          objectFit="contain"
          alt=""
        />
        {leftIntro && <div className={styles.leftIntro}>{leftIntro}</div>}
        {leftTitle && <div className={styles.leftTitle}>{leftTitle}</div>}
      </div>

      <div className={styles.listContent}>
        {listTitle && <div className={styles.listTitle}>{listTitle}</div>}

        <ol className={styles.list}>
          {listItems?.map(item => (
            <li key={item.title} className={styles.listItem}>
              {item.title}
            </li>
          ))}
        </ol>
      </div>
    </Container>
  </section>
)

export default AboutHero
