import * as React from "react"
import { useState, useCallback } from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import cx from "classnames"

import Section from "@components/elements/section"
import LinesGap from "@components/elements/linesGap"
import ScrollTrigger from "@components/elements/scrollTrigger"
import Lightbox, { CloseButton } from "@components/elements/lightbox"

import styles from "./persons.module.scss"

interface Person {
  name: string
  position?: string
  text?: string
  image: ImageDataLike
}

interface PersonsProps {
  title?: string
  text?: string
  columnsNr: number
  items: Array<Person>
}

const Persons = ({ title, text, columnsNr = 4, items = [] }: PersonsProps) => {
  const [activePerson, setActivePerson] = useState<Person | null>(null)
  const show = useCallback(item => setActivePerson(item), [])
  const hide = useCallback(() => setActivePerson(null), [])

  if (!items.length) {
    return null
  }

  return (
    <>
      <Section className={styles.persons}>
        {title && <LinesGap title={title} text={text} variant="primary" />}

        <ul className={cx(styles.personList, styles[`col${columnsNr}`])}>
          {items.map(item => {
            const image = getImage(item.image)

            return (
              <li className={styles.person} key={item.name}>
                <ScrollTrigger>
                  <GatsbyImage
                    className={styles.personImage}
                    image={image}
                    alt={`${item.name} portrait`}
                  />
                </ScrollTrigger>
                {item.name && (
                  <h3 className={styles.personName}>{item.name}</h3>
                )}
                {item.position && (
                  <div className={styles.personPosition}>{item.position}</div>
                )}
                {item.text && (
                  <button
                    className={styles.personButtonOpen}
                    type="button"
                    onClick={() => show(item)}
                  >
                    Learn more
                  </button>
                )}
              </li>
            )
          })}
        </ul>
      </Section>

      {activePerson && (
        <Lightbox
          onClose={hide}
          className={styles.lightbox}
          containerClassName={styles.lightboxContainer}
          showCloseButton={false}
        >
          <div className={styles.lightboxPerson}>
            <GatsbyImage
              className={styles.lightboxImage}
              image={getImage(activePerson.image)}
              aspectRatio={1 / 1}
              alt={`${activePerson.name} portrait`}
            />
            {activePerson.name && (
              <div className={styles.lightboxName}>{activePerson.name}</div>
            )}
            {activePerson.position && (
              <div className={styles.lightboxPosition}>
                {activePerson.position}
              </div>
            )}
            {activePerson.text && (
              <div className={styles.lightboxText}>{activePerson.text}</div>
            )}
            <button
              className={styles.lightboxButtonClose}
              type="button"
              onClick={hide}
            >
              Close
            </button>
            <CloseButton onClick={hide} className={styles.lightboxCrossClose} />
          </div>
        </Lightbox>
      )}
    </>
  )
}

export default Persons
